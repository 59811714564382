import React, { useState, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import Layout from "templates/Layout";
import Content from "templates/content";
import Help from "templates/Help";

const style = Object.freeze({
  container: css`
    overflow: hidden;
  `,
});

export const page = Object.freeze({
  index: css`
    transform: translateY(0);
  `,
  help: css`
    margin: 0;
  `,
});

export const help = Object.freeze({
  index: css`
    transform: translateY(0);
  `,
  help: css``,
});

export type PageKey = keyof typeof page;

const IndexPage: React.FC = () => {
  const [transition, setTransition] = useState<string>("index");
  const [delta, setDelta] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const transform = (key: PageKey) => {
    if (transition === key) {
      return;
    }
    setTransition(key);
    if (key === "index") {
      setHeight(indexRef.current.clientHeight);
    }
    if (key === "help") {
      setHeight(helpRef.current.clientHeight);
    }
  };

  const indexRef = useRef<any>();
  const helpRef = useRef<any>();

  useEffect(() => {
    setDelta(indexRef.current.clientHeight);
    setHeight(indexRef.current.clientHeight);
  }, []);

  return (
    <Layout transition={transition} navigate={transform}>
      <div
        css={[
          style.container,
          css`
            height: ${height}px;
          `,
        ]}
      >
        <div
          ref={indexRef}
          css={css`
            padding: 32px 0;
            transition: all 0.5s linear;
            ${transition === "index"
              ? ""
              : `transform: translateY(-${delta}px)`};
            ${page[transition as PageKey]};
          `}
        >
          <Content />
        </div>
        <div
          ref={helpRef}
          css={css`
            height: 100vh;
            overflow: scroll;
            transition: all 0.5s linear;
            ${transition === "index"
              ? ""
              : `transform: translateY(-${delta}px)`};
            ${help[transition as PageKey]};
          `}
        >
          <Help goBack={() => transform("index")} />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
