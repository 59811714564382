import axios from "axios";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import {isProduction} from "src/constants";
import JSZip from "jszip";

export const baseURL =
  process.env.GATSBY_APP_API_URL || "http://localhost:3000";

const ffmpeg = createFFmpeg({ log: !isProduction });
ffmpeg.load();

export const client = axios.create({
  baseURL,
  timeout: 10000,
});

const calcDuration = (head: number, tail: number) => {
  const digit = 100000;
  const duration = tail * digit - head * digit;
  return duration / digit;
};

const getBase64 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      if (!e.target) {
        reject(new Error("target is empty"));
        return;
      }
      resolve(e.target.result as string);
    };

    reader.onerror = function (e) {
      reject(e);
    };

    reader.readAsDataURL(file);
  });
};

interface Point {
  head: number;
  tail: number;
  name: string;
}

const execute = async (fileName: string, item: Point): Promise<Point & { src: string, blob: Blob }> => {
  const { head, tail, name } = item;
  const duration = calcDuration(head, tail);
  const basePath = `output_${new Date().getTime()}`;
  const path = `${basePath}_${name}`;
  await ffmpeg.run(
    "-i",
    fileName,
    "-ss",
    String(head),
    "-t",
    String(duration),
    path
  );
  const data = ffmpeg.FS("readFile", path);
  const blob = new Blob([data.buffer], { type: "audio/mp3" })
  return {
    src: URL.createObjectURL(blob),
    blob,
    ...item,
  };
};

export const processFileAsync = async (file: File, points: Point[]) => {
  const dataUrl = await getBase64(file);
  const baseFileName = `input_${new Date().getTime()}`;
  ffmpeg.FS("writeFile", baseFileName, await fetchFile(dataUrl));
  let files = [] as any[];

  try {
    for (const  item of points) {
      files.push(await execute(baseFileName, item));
    }
    return {
      files
    }
  } catch (e) {
    console.error(e);
  }
};

export const zipFiles = async (files: any[]) => {
  const zip = new JSZip()
  files.map((item: any) => {
    zip.file(item.name, item.blob, { base64: true })
  })
  const blob = await zip.generateAsync({ type: 'blob' })
  return blob
}
