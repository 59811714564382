import React from "react";
import { css } from "@emotion/react";
import Button from "atoms/Button";
import Icon from "atoms/Icon";
import { Point } from "models/audio";
import { color, limit } from "constants/index";
import Audio from "models/audio";
import PointsHistory from "models/pointHistory";

const style = Object.freeze({
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    width: 480px;
    padding: 12px;
    background: ${color["gray-1"]};
    border-radius: 28px;
  `,
  pin: css`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  count: css`
    margin-left: 16px;
    font-weight: bold;
    opacity: 0;

    p {
      white-space: nowrap;
    }
  `,
  commands: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      margin-right: 16px;
    }
  `,
  actions: css`
    width: 50%;
    border-right: 1px solid ${color["gray-2"]};
  `,
  destroy: css`
    width: 128px;
    padding-left: 16px;
  `,
});

const getFilename = (name: string) => (index: number) => {
  const extname = "." + name.split(".").slice(-1)[0];
  const seqname = ("000" + index).slice(-3);
  return [name.replace(extname, ""), seqname].join("_") + extname;
};

interface Props {
  current?: Audio;
  currentTime?: number;
  duration?: number;
  scenes?: PointsHistory;
  onPin?: (points: Array<Point>) => void;
  onRedo?: (points: Array<Point>) => void;
  onUndo?: (points: Array<Point>) => void;
  onDeleteAll: () => void;
}

const Control: React.FC<Props> = ({
  current,
  currentTime,
  duration,
  scenes,
  onPin = () => ({}),
  onRedo,
  onUndo,
  onDeleteAll = () => ({}),
}) => {
  const points = scenes?.cursor || [];

  return (
    <div css={style.container}>
      <div css={style.pin}>
        <Button
          type="primary"
          icon="pin"
          label="ピンを立てる"
          disabled={points.length >= limit.maxPinList}
          onClick={() => {
            if (!currentTime || !duration || !points) {
              return;
            }
            const point = Math.floor(currentTime * 10) / 10;
            const _duration = Math.floor(duration * 10) / 10;
            const newPoints = [...points];

            if (!current) {
              return;
            }

            const filename = getFilename(current.name);
            if (points.length === 0) {
              newPoints.push({
                name: filename(newPoints.length),
                head: 0,
                tail: _duration,
              });
            }

            const head = newPoints[newPoints.length - 1].head;
            const params = [
              ...newPoints.slice(0, -1),
              { name: filename(newPoints.length), head, tail: point },
              {
                name: filename(newPoints.length + 1),
                head: point,
                tail: duration,
              },
            ];
            onPin(params);
          }}
        />
        <div
          css={[
            style.count,
            points.length > 0 &&
              css`
                opacity: 1;
              `,
          ]}
        >
          <p> x {points.length}</p>
        </div>
      </div>
      <div css={style.actions}>
        <ul css={style.commands}>
          <li>
            <Icon icon="undo" disabled={!scenes?.canUndo} onClick={onUndo} />
          </li>
          <li>
            <Icon icon="redo" disabled={!scenes?.canRedo} onClick={onRedo} />
          </li>
        </ul>
      </div>
      <div css={style.destroy}>
        <Icon icon="cancel" onClick={onDeleteAll} />
      </div>
    </div>
  );
};

export default Control;
