import React, { useState, useMemo } from "react";
import { css } from "@emotion/react";
import { color } from "../../constants";

const style = Object.freeze({
  container: css`
    position: relative;
  `,
  pulldown: css`
    position: absolute;
    top: 32px;
    z-index: 100;
    adding: 4px 0;
    background: ${color.white};
    box-shadow: 3px 6px 10px 3px ${color["gray-2"]};
    border-radius: 4px;
    font-size: 0.8rem;
  `,
  selected: css`
    white-space: nowrap;
    padding: 4px 0px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-bottom: 0px solid ${color.fontColor};
  `,
  item: css`
    white-space: nowrap;
    padding: 8px 12px;
    width: 100%;
    cursor: pointer;
  `,
  divider: css`
    width: 100%;
    margin: 0;
    margin-top: 4px;
    height: 1px;
    background: ${color["gray-1"]};
    position: relative;
    top: 8px;
  `,
});

interface MenuItem {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface Props {
  activator: JSX.Element;
  list: Array<MenuItem>;
  pulldownStyle?: any;
}

const Menu = ({ activator, list, pulldownStyle }: Props) => {
  const [open, setOpen] = useState(false);

  const _activator = useMemo(
    () =>
      React.cloneElement(activator, {
        onClick: () => {
          setOpen(true);
        },
      }),
    [activator]
  );
  return (
    <div css={style.container}>
      {_activator}
      {open && (
        <ul
          css={[style.pulldown, pulldownStyle]}
          onMouseLeave={() => {
            setOpen(false);
          }}
        >
          {list.map((item, index) => {
            return (
              <li
                key={item.label}
                onClick={() => {
                  item.onClick();
                  setOpen(false);
                }}
                css={style.item}
              >
                <div>{item.label}</div>
                {list.length !== index + 1 && <div css={style.divider} />}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Menu;
