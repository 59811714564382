import { Point } from "./audio";

export default class PointsHistory {
  private _undoStack: Array<Array<Point>> = [];
  private _redoStack: Array<Array<Point>> = [];
  private _cursor: Array<Point>;

  static create(cursor: Array<Point>) {
    return new PointsHistory({ undo: [], redo: [], cursor });
  }

  constructor({
    undo,
    redo,
    cursor,
  }: {
    undo: Array<Array<Point>>;
    redo: Array<Array<Point>>;
    cursor: Array<Point>;
  }) {
    this._undoStack = undo;
    this._redoStack = redo;
    this._cursor = cursor;
  }

  get cursor() {
    return this._cursor;
  }

  get undoSize() {
    return this._undoStack.length;
  }

  get redoSize() {
    return this._redoStack.length;
  }

  get canRedo() {
    return this.redoSize > 0;
  }

  get canUndo() {
    return this.undoSize > 0;
  }

  clear() {
    return this.push([])
  }

  push(item: Array<Point>) {
    return new PointsHistory({
      undo: [...this._undoStack, this.cursor],
      redo: [],
      cursor: item,
    });
  }

  undo() {
    if (!this.undoSize) {
      return;
    }
    const [item] = this._undoStack.slice(-1);
    return new PointsHistory({
      undo: this._undoStack.slice(0, -1),
      redo: [...this._redoStack, this.cursor],
      cursor: item,
    });
  }

  redo() {
    if (!this.redoSize) {
      return;
    }

    const [item] = this._redoStack.slice(-1);
    return new PointsHistory({
      undo: [...this._undoStack, this.cursor],
      redo: this._redoStack.slice(0, -1),
      cursor: item,
    });
  }
}
