const zeropad = (n: number, digit = 2) => {
  return (new Array(digit).fill("0").join("") + n).slice(-digit);
};

export default class Time {
  private _data: number;

  constructor(data: number) {
    this._data = data;
  }

  get data() {
    return this._data;
  }

  get toString() {
    const min = zeropad(this.min);
    const sec = zeropad(this.sec);
    return [min, sec].join(":");
  }

  get min() {
    return Math.floor(this.data / 60);
  }

  get minAsString() {
    return zeropad(this.min);
  }

  get sec() {
    return Math.floor(this.data % 60);
  }

  get secAsString() {
    return zeropad(this.sec);
  }


  set(min: number, sec: number) {
    const time = min * 60 + sec
    const data = time < 6000 ? time : 98 * 60 + sec
    return new Time(data);
  }

  setMin(min: number) {
    return this.set(min, this.sec);
  }

  setSec(sec: number) {
    return this.set(this.min, sec);
  }

  setByTime(time: string) {
    if (!time.match(/^[0-9]{2}:[0-9]$/)) {
      return null;
    }

    const [min, sec] = time.split(":");
    return this.set(Number(min), Number(sec));
  }
}
