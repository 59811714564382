import { css } from "@emotion/react";
import { color } from "../../constants";

const style = Object.freeze({
  container: css`
    position: relative;
  `,
  slider: css`
    display: block;
    -webkit-appearance: none;
    background: ${color["gray-2"]};
    height: 4px;
    width: 300px;
    margin: auto;
    border-radius: 2px;
    cursor: pointer;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: ${color["gray-6"]};
      position: relative;
      z-index: 10;
    }

    &:before {
      position: absolute;
      content: "";
      display: block;
      height: 4px;
      background: ${color["gray-4"]};
      z-index: 1;
      top: 0;
      border-radius: 2px;
    }
  `,
});

interface Props {
  position: number;
  onSeekEnd?: (params: any) => void;
}

const Slider: React.FC<Props> = ({ position = 0, onSeekEnd = () => ({}) }) => {
  const width = (300 * (position || 0)) / 100;
  return (
    <div css={style.container}>
      <input
        css={[
          style.slider,
          css`
            &:before {
              width: ${width}px;
            }
          `,
        ]}
        type="range"
        min={0}
        max={100}
        step={1}
        value={position || 0}
        onChange={(e: any) => {
          onSeekEnd({ current: position, next: e.target.value });
        }}
      />
    </div>
  );
};

export default Slider;
