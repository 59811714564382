import { css } from "@emotion/react";
import Button from "atoms/Button";
import ProcessingIcon from "assets/images/logo.svg";
import SuccessIcon from "assets/images/illust-success.svg";
import ErrorIcon from "assets/images/illust-fail.svg";

const style = Object.freeze({
  container: css`
    position: relative;
    min-height: 400px;
    margin: 32px auto;
  `,
  header: css`
    padding: 32px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
  spacer: css`
    height: 240px;
  `,
  title: css`
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 16px;

    span:nth-of-type(2) {
      animation: 0.75s linear 0s infinite alternate blinking;
    }
    span:nth-of-type(3) {
      animation: 0.75s linear 0.5s infinite alternate blinking;
    }

    @keyframes blinking {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  `,
  content: css`
    text-align: center;
  `,
  footer: css`
    width: 240px;
    margin: 32px auto;
    margin-bottom: 0;
  `,
  image: css`
    height: 180px;
    width: 149px;
  `,
  icon: css`
    @keyframes pendulum {
      0% {
        transform: rotate(-10deg);
      }
      50% {
      }
      100% {
        transform: rotate(10deg);
      }
    }
    height: 400px;
    position: absolute;
    top: 32px;

    animation: 0.5s ease-in-out 0s infinite alternate pendulum;
  `,
  scene: css`
    transition: all 0.5s linear;
  `,
  unfocus: css`
    opacity: 0;
    display: none;
  `,
  focus: css`
    opacity: 1;
  `,
});

interface Props {
  status: StatusKey;
  onClose: () => void;
}

export type StatusKey = keyof typeof Scene;

export const Scene = Object.freeze({
  processing: "processing",
  success: "success",
  error: "error",
});

const Processing: React.FC<Props> = ({ status, onClose }) => {
  const visibillity = (scene: string) =>
    status === scene ? style.focus : style.unfocus;

  return (
    <div css={style.container}>
      <div css={[style.scene, visibillity(Scene.processing)]}>
        <div css={style.header}>
          <div css={style.icon}>
            <img src={ProcessingIcon} alt="処理中" />
          </div>
          <div css={style.spacer} />
          <h2 css={style.title}>
            処理中<span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </div>
        <div css={style.content}>
          <p>音声ファイルを分割しています。しばらくお待ちください。</p>
        </div>
        <div css={style.footer}></div>
      </div>
      <div css={[style.scene, visibillity(Scene.success)]}>
        <div
          css={[
            style.header,
            css`
              padding-bottom: 16px;
            `,
          ]}
        >
          <img src={SuccessIcon} alt="成功" />
          <h2 css={style.title}>分割完了</h2>
        </div>
        <div
          css={[
            style.content,
            css`
              p {
                margin: 0;
                padding: 0px;
                padding-bottom: 2px;
                font-size: 0.8rem;
              }
            `,
          ]}
        >
          <p>音声ファイルの分割が完了しました。</p>
          <p>ダウンロードボタンからファイルをダウンロードしてください。</p>
        </div>
        <div css={style.footer}>
          <Button type="primary" label="閉じる" onClick={onClose} />
        </div>
      </div>
      <div css={[style.scene, visibillity(Scene.error)]}>
        <div
          css={[
            style.header,
            css`
              padding-bottom: 16px;
            `,
          ]}
        >
          <img src={ErrorIcon} alt="処理エラー" />
          <h2 css={style.title}>処理エラー</h2>
        </div>
        <div
          css={[
            style.content,
            css`
              p {
                margin: 0;
                padding: 0px;
                padding-bottom: 2px;
                font-size: 0.8rem;
              }
            `,
          ]}
        >
          <p>処理中にエラーが発生しました。</p>
          <p>時間を置いて再度試して見てください。</p>
        </div>
        <div css={style.footer}>
          <Button type="primary" label="閉じる" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default Processing;
