import { css } from "@emotion/react";
const style = Object.freeze({
  timestamp: css`
    font-size: 1.1rem;

    .decimal {
      font-size: 0.7rem;
    }
  `,
});

const Time = ({ time }: { time: number }) => {
  const zeropad = (t: number) => ("00" + t).slice(-2);
  return (
    <span css={style.timestamp}>
      {zeropad(Math.floor(time / 60))} : {zeropad(Math.floor(time % 60))}
      {/* <span className="decimal">{zeropad(Math.floor(time * 100))}</span> */}
    </span>
  );
};

export default Time
