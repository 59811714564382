export default class Listener {
  public cleaners: any[] = [];

  register = (target: any, event: string, cb: Function) => {
    target.addEventListener(event, cb);

    this.cleaners.push(() => target.removeEventListener(event, cb));
  };

  clean = () => {
    this.cleaners.map((cleaner: any) => {
      cleaner();
    });
  };
}
