import React, { useState } from "react";
import { css } from "@emotion/react";
import { color } from "../../constants";

const style = Object.freeze({
  container: css`
    position: relative;
  `,
  pulldown: css`
    position: absolute;
    top: 32px;
    z-index: 1;
    adding: 4px 0;
    background: ${color.white};
    box-shadow: 3px 6px 10px 3px ${color["gray-2"]};
    border-radius: 4px;
  `,
  selected: css`
    white-space: nowrap;
    padding: 4px 0px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    border-bottom: 0px solid ${color.fontColor};
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid ${color.fontColor};
    }

    &:before {
      font-size: 0.8rem;
      display: inline-block;
      margin-right: 4px;
      content: "▼";
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    &:hover:before {
      content: "▼";
      opacity: 1;
    }
  `,
  item: css`
    white-space: nowrap;
    padding: 8px 12px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background: ${color.info};
    }
  `,
  divider: css`
    width: 100%;
    margin: 0;
    margin-top: 4px;
    height: 1px;
    background: ${color["gray-1"]};
    position: relative;
    top: 8px;
  `,
});

interface LabelValue<T> {
  label: string;
  value: T;
}

interface Props<T> {
  value: T;
  list: Array<LabelValue<T>>;
  onSelect?: (item: LabelValue<T>) => void;
  renderItem?: (item: LabelValue<T>) => JSX.Element | string;
}

const PlayerSelect = <T extends unknown>({
  value,
  list,
  onSelect,
  renderItem,
}: Props<T>) => {
  const [open, setOpen] = useState(false);
  const selected = list.find((item) => item.value === value) || list[0];

  const _renderItem = (item: LabelValue<T>) =>
    renderItem ? renderItem(item) : item.label;
  return (
    <div css={style.container}>
      <span css={style.selected} onClick={() => setOpen(true)}>
        {_renderItem(selected)}
      </span>
      {open && (
        <ul
          css={style.pulldown}
          onMouseLeave={() => {
            setOpen(false);
          }}
        >
          {list.map((item, index) => {
            if (item.value === selected.value) {
              return null;
            }
            return (
              <li
                key={item.label}
                onClick={() => {
                  setOpen(false);
                  if (onSelect) {
                    onSelect(item);
                  }
                }}
                css={style.item}
              >
                <div>{_renderItem(item)}</div>
                {list.length !== index + 1 && <div css={style.divider} />}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default PlayerSelect;
