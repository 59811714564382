import React, { useMemo, useEffect, useRef, useState, RefObject } from "react";
import { css } from "@emotion/react";
import Time from "atoms/Time";
import Icon from "atoms/Icon";
import { Point } from "models/audio";
import Listener from "models/listener";
import { color } from "constants/index";
import Menu from "components/molecules/Menu";

const style = Object.freeze({
  middleSpan: css`
    display: inline-block;
    margin: 0 16px;
  `,
  filename: css`
    flex: 1;
    white-space: nowrap;
    input {
      border: 0;
      border-radius: 4px;
      padding: 4px 4px;
      letter-spacing: 0.1rem;
      width: 100%;
    }
  `,
  time: css`
    flex: 1;
    display: flex;
    cursor: pointer;
    justify-content: center;
  `,
  icon: css`
    margin-right: 4px;
  `,
  actions: css`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
  audio: css`
    display: none;
  `,
  miniPlayer: css``,
});

interface Props {
  point: Point;
  onChangePoint?: (point: Point) => void;
  onRemovePoint?: () => void;
  onClickTime?: (currentTime: number) => void;
}

const Status = Object.freeze({
  pause: "pause",
  playing: "playing",
});

const MiniPlayer = ({ link }: { link?: string }) => {
  const audioRef = useRef<any>();
  const [status, setStatus] = useState(Status.pause);

  useEffect(() => {
    const target = audioRef.current!;
    const _onEnded = () => {
      setStatus(Status.pause);
    };

    const listeners = new Listener();
    listeners.register(target, "ended", _onEnded);

    return listeners.clean;
  }, [status]);

  const playerIcon =
    !audioRef.current || audioRef.current.paused ? "play" : "pause";

  const audio = useMemo(
    () => <audio ref={audioRef} css={style.audio} src={link} />,
    [link]
  );

  return (
    <>
      {audio}
      <Icon
        icon={playerIcon}
        css={style.icon}
        disabled={!link}
        color={color["gray-4"]}
        onClick={() => {
          if (!audioRef.current) {
            return;
          }
          const { current } = audioRef;
          if (current.paused) {
            current.play();
            setStatus(Status.playing);
          } else {
            current.pause();
            setStatus(Status.pause);
          }
        }}
      />
    </>
  );
};

const Default: React.FC<Props & { onEdit: (e: any) => void }> = ({
  point,
  onClickTime,
  onRemovePoint,
  onEdit,
}) => {
  const ancherRef = useRef<HTMLLinkElement>();
  return (
    <>
      <div css={style.filename}>{point.name}</div>
      <div css={style.time}>
        <a
          onClick={() => {
            if (!onClickTime) {
              return;
            }
            onClickTime(point.head);
          }}
        >
          <Time time={point.head} />
        </a>
        <span css={style.middleSpan}>&nbsp;~&nbsp;</span>
        <a
          onClick={() => {
            if (!onClickTime) {
              return;
            }
            onClickTime(point.tail);
          }}
        >
          <Time time={point.tail} />
        </a>
      </div>
      <div css={style.actions}>
        <Icon
          icon="edit"
          css={style.icon}
          color={color["gray-4"]}
          onClick={onEdit}
        />
        <Icon
          icon="delete"
          css={style.icon}
          color={color["gray-4"]}
          onClick={onRemovePoint}
        />
        <MiniPlayer link={point.link} />
        <Menu
          activator={
            <Icon
              icon="menu"
              size="18px"
              css={style.icon}
              disabled={!point.link}
            />
          }
          pulldownStyle={css`
            left: -64px;
          `}
          list={[
            {
              label: "ダウンロード",
              onClick: () => saveAs(point.blob!, point.name),
              disabled: !point.blob,
            },
          ]}
        />
        <a
          css={css`
            display: none;
          `}
          ref={ancherRef as RefObject<any>}
          href={point.link}
          target="_blank"
          rel="noreferrer"
          download
        ></a>
      </div>
    </>
  );
};

export default Default;
