import React, { useEffect, useState, useRef, RefObject, useMemo } from "react";
import { css } from "@emotion/react";
import { color } from "constants/index";
import Listener from "models/listener";
import Slider from "components/atoms/Slider";
import PlayerSelect from "components/molecules/PlayerSelect";
import Menu from "components/molecules/Menu";
import Icon from "components/atoms/Icon";
import Time from "components/atoms/Time";

const style = Object.freeze({
  container: css`
    background: ${color.gray};
    width: 640px;
    border-radius: 32px;
    margin: auto;
    padding: 16px;
  `,
  content: css`
    display: flex;
    padding: 0 32px;
    align-items: center;
  `,
  main: css`
    width: 100%;
    padding-top: 28px;

    &:hover .handler {
      background: black;
      cursor: pointer;
    }
  `,
  player: css`
    display: none;
  `,
  title: css`
    font-size: 2rem;
    text-align: center;
    padding: 16px;
  `,
  time: css`
    display: flex;
    justify-content: center;
    align-item: center;
    margin-top: 16px;
  `,
  item: css`
    padding: 16px;
    padding-left: 64px;
    border-bottom: 1px solid #f0f0f0;
  `,
  icon: css`
    margin-right: 16px;
    cursor: pointer;
  `,
  speed: css`
    font-size: 1.25rem;
  `,
  timestamp: css`
    font-size: 1.1rem;

    .decimal {
      font-size: 0.7rem;
    }
  `,
});

interface AudioObj {
  path: string;
  name: string;
}

interface Props {
  file?: AudioObj;
  currentTime: number;
  duration: number;
  onTimeupdate: (pos: number) => void;
  onLoadedData: (params: { duration: number }) => void;
}

const Status = Object.freeze({
  initial: "initial",
  pause: "pause",
  playing: "playing",
});

const DEFAULT_SPEED = 1;
const DEFAULT_VOLUME = 1.0;

const PLAYBACK_SPEED_LIST = [
  { label: "0.5", value: 0.5 },
  { label: "1.0", value: 1 },
  { label: "1.25", value: 1.25 },
  { label: "1.5", value: 1.5 },
  { label: "1.75", value: 1.75 },
  { label: "2.0", value: 2 },
];

interface IPlayer extends React.FC<Props> {
  setCurrentTime?: (point: number) => void;
}

const Player: IPlayer = ({
  file,
  currentTime,
  duration,
  onLoadedData,
  onTimeupdate,
}) => {
  const audioRef = useRef<HTMLAudioElement>();
  const ancherRef = useRef<HTMLLinkElement>();
  const [speed, setSpeed] = useState(DEFAULT_SPEED);

  useEffect(() => {
    const target = audioRef.current!;
    Player.setCurrentTime = (point: number) => {
      target.currentTime = point;
    };

    target.playbackRate = speed;

    const _onLoadedData = () => {
      const { currentTime: _currentTime, duration: _duration } =
        audioRef?.current || {};
      onTimeupdate(_currentTime || 0);
      onLoadedData({ duration: _duration || 0 });
    };

    const _onTimeupdate = () => {
      onTimeupdate(audioRef.current?.currentTime || 0);
    };

    const listeners = new Listener();
    listeners.register(target, "loadeddata", _onLoadedData);
    listeners.register(target, "timeupdate", _onTimeupdate);

    return listeners.clean;
  }, [file]);

  const onSeek = ({ next }: any) => {
    const currentTime = (duration * next) / 100;
    audioRef.current!.currentTime = currentTime;
  };

  const onChangeSpeed = (item: { value: number }) => {
    setSpeed(item.value);
    if (audioRef.current) {
      audioRef.current.playbackRate = item.value;
    }
  };

  // memoize to persist instance of audio element
  const audio = useMemo(
    () => (
      <audio
        controls
        css={style.player}
        ref={audioRef as RefObject<HTMLAudioElement>}
        src={file?.path}
      />
    ),
    [file]
  );

  const progressRate = Math.floor((currentTime / duration) * 100);

  return (
    <div css={style.container}>
      {audio}
      <h2 css={style.title}>{file?.name || "- - -"}</h2>
      <div css={style.content}>
        {audioRef.current?.paused ? (
          <Icon
            icon="play"
            size="32px"
            css={style.icon}
            onClick={() => {
              if (!file) {
                return
              }
              audioRef.current?.play();
            }}
          />
        ) : (
          <Icon
            icon="pause"
            size="32px"
            css={style.icon}
            onClick={() => {
              audioRef.current?.pause();
            }}
          />
        )}
        <div css={style.speed}>
          <PlayerSelect<number>
            value={speed}
            list={PLAYBACK_SPEED_LIST}
            onSelect={onChangeSpeed}
            renderItem={({ label }) => (
              <>
                <span style={{ fontSize: "0.8rem" }}>x </span>
                <span style={{ textAlign: "right" }}>{label}</span>
              </>
            )}
          />
        </div>
        <div css={style.main}>
          <Slider position={progressRate} onSeekEnd={onSeek} />
          <div css={style.time}>
            <span>
              <Time time={currentTime} />
            </span>
            <span>&nbsp;/&nbsp;</span>
            <Time time={duration} />
          </div>
        </div>
        <Menu
          activator={
            <Icon
              icon="menu"
              size="32px"
              css={style.icon}
              disabled={!file?.path}
            />
          }
          list={[
            {
              label: "ダウンロード",
              onClick: () => ancherRef.current?.click(),
            },
          ]}
        />
        <a
          css={css`
            display: none;
          `}
          ref={ancherRef as RefObject<any>}
          href={file?.path}
          target="_blank"
          rel="noreferrer"
          download
        ></a>
      </div>
    </div>
  );
};

export default Player;
