import React from "react";
import { css } from "@emotion/react";
import Button from "../../../components/atoms/Button";
import Icon from "../../../components/atoms/Icon";
import Audio from "src/models/audio";

const style = Object.freeze({
  footer: css`
    padding: 32px;
    width: 320px;
    margin: auto;
  `,
  icon: css`
    width: 100%;
  `,
  downloadLink: css`
    display: none;
  `,
});

interface Props {
  current?: Audio;
  onSubmit: () => Promise<void>;
  onDownload: () => Promise<void>;
}

const Footer: React.FC<Props> = ({ current, onSubmit, onDownload }) => {
  return (
    <div css={style.footer}>
      <Icon css={style.icon} icon="arrowdown" size="24px" />
      <Button type="primary" icon="cut" label="カット" onClick={onSubmit} />
      <Button
        type="outline"
        icon="download"
        disabled={!current?.archiveUrl}
        label="ダウンロード"
        onClick={onDownload}
      />
    </div>
  );
};

export default Footer;
