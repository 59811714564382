import React from 'react';
import { css } from "@emotion/react";
import { Point } from "../../models/audio";
import PointRow from "./point";

const style = Object.freeze({
  unselectFile: css`
    height: 400px;
    padding: 128px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
    }
  `
});

interface Props {
  points: Array<Point>;
  onSeek: (time: number) => void;
  onChange: (points: Array<Point>) => void;
}

const PinList: React.FC<Props> = ({ points, onSeek, onChange }) => {
  return (
    <div>
      {points.length > 0 ? (
        points.map((point: Point, index: number) => {
          const _onRemove = () => {
            const newPoints = [...points];
            newPoints.splice(index, 1);
            onChange(newPoints);
          };
          const _onChange = (_point: Point) => {
            const newPoints = [...points];
            newPoints[index] = _point;
            onChange(newPoints);
          };
          return (
            <PointRow
              key={index}
              point={point}
              onClickTime={onSeek}
              onChangePoint={_onChange}
              onRemovePoint={_onRemove}
            />
          );
        })
      ) : (
        <div css={style.unselectFile}>
          <p>ファイルが選択されていません。</p>
          <p>左サイドバーより、ファイルを選択してください。</p>
        </div>
      )}
    </div>
  );
};

export default React.memo(PinList, (prevProps: Props, nextProps: Props) => {
  return prevProps.points == nextProps.points
});
