import React, { useState } from "react";
import { css } from "@emotion/react";
import Icon from "../../../components/atoms/Icon";
import TimeForm from "../../../components/molecules/TimeForm";
import { color } from "../../../constants";

const style = Object.freeze({
  middleSpan: css`
    display: inline-block;
    margin: 0 16px;
  `,
  filename: css`
    flex: 1;
    white-space: nowrap;
    input {
      border: 0;
      border-radius: 4px;
      padding: 4px 4px;
      letter-spacing: 0.1rem;
      width: 100%;
    }
  `,
  time: css`
    flex: 1;
    display: flex;
    cursor: pointer;
    justify-content: center;
  `,
  icon: css`
    margin-right: 4px;
  `,
  actions: css`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
});

interface Point {
  name: string;
  head: number;
  tail: number;
  file?: any;
}

interface Props {
  point: Point;
  onChangePoint?: (point: Point) => void;
  onClickTime?: (currentTime: number) => void;
}

const validate = (params: Point) => {
  if (!params.name) {
    return "ファイル名が不正です。";
  }

  if (params.name.length > 36) {
    return "ファイル名は36文字以下で設定してください。";
  }

  if (!params.head) {
    return "開始時間が不正です。";
  }

  if (!params.tail) {
    return "終了時間が不正です。";
  }

  if (params.tail <= params.head) {
    return "終了時間は開始時間より後に設定してください。";
  }
  return null;
};

const Form: React.FC<Props & { onDone: Function }> = ({
  point,
  onChangePoint,
  onDone,
}) => {
  const [name, setName] = useState(point.name);
  const [head, setHead] = useState(point.head);
  const [tail, setTail] = useState(point.tail);

  const onChangeText = (cb: Function) => (e: any) => cb(e.target.value);

  return (
    <>
      <div css={style.filename}>
        <input type="text" value={name} onChange={onChangeText(setName)} />
      </div>
      <div css={style.time}>
        <TimeForm time={head} onChange={(time: number) => setHead(time)} />
        <span css={style.middleSpan}>&nbsp;~&nbsp;</span>
        <TimeForm time={tail} onChange={(time: number) => setTail(time)} />
      </div>
      <div css={style.actions}>
        <Icon
          icon="check"
          css={style.icon}
          color={color.success}
          onClick={() => {
            if (!onChangePoint) {
              return;
            }
            const params = {
              name,
              head,
              tail,
            };

            const error = validate(params);
            if (error) {
              // TODO: display toast
              alert(error);
              return;
            }
            onChangePoint(params);
            onDone();
          }}
        />
        <Icon
          icon="cancel"
          css={style.icon}
          size="16px"
          color={color["gray-4"]}
          onClick={() => {
            onDone();
          }}
        />
        <Icon
          icon="sound"
          css={style.icon}
          disabled
          color={color["gray-4"]}
        />
        <Icon
          icon="menu"
          css={style.icon}
          disabled
          color={color["gray-4"]}
        />
      </div>
    </>
  );
};

export default Form;
