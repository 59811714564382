import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import { css } from "@emotion/react";
import { useContext } from "context/index";
import Player from "organisms/Player";
import PinList from "organisms/PinList";
import Control from "organisms/Control";
import Footer from "templates/content/Footer";
import Modal from "templates/Modal";
import { color } from "constants/index";
import { Point } from "models/audio";
import PointsHistory from "models/pointHistory";
import Processing from "templates/dialog/Processing";
import { processFileAsync, zipFiles } from "src/services";

const style = Object.freeze({
  container: css`
    width: 872px;
    min-height: 400px;
    margin: auto;
  `,
  header: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
  `,
  help: css`
    margin: 32px 0;
  `,
  content: css`
    overflow: scroll;
    height: 400px;
    background: ${color.gray};
    border-radius: 16px;
    padding: 16px;
  `,
  footer: css`
    padding: 32px;
    width: 320px;
    margin: auto;
  `,
  hidden: css`
    display: none;
  `,
});

const scrollBottom = (ele?: HTMLElement, delay: number = 100) => {
  if (!ele) {
    return;
  }
  setTimeout(() => {
    ele.scrollTo({ top: ele.scrollHeight, behavior: "smooth" });
  }, delay);
};

const Content: React.FC = () => {
  const {
    state: { current },
    update: { updateCurrent },
  } = useContext();
  const contentRef = useRef<any>();
  const [scenes, setScenes] = useState<PointsHistory>();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const points = scenes?.cursor || [];

  useEffect(() => {
    if (!current) {
      setScenes(undefined);
      setCurrentTime(0);
      setDuration(0);
      return;
    }

    setScenes(PointsHistory.create(current.points));
  }, [current]);

  return (
    <div css={style.container}>
      <Player
        file={current}
        duration={duration}
        currentTime={currentTime}
        onLoadedData={({ duration: _duration }) => {
          setDuration(_duration);
        }}
        onTimeupdate={(pos: number) => {
          setCurrentTime(pos);
        }}
      />
      <div css={style.header}>
        <Control
          scenes={scenes}
          current={current}
          currentTime={currentTime}
          duration={duration}
          onPin={(points: Array<Point>) => {
            setScenes(scenes?.push(points));
            scrollBottom(contentRef.current);
          }}
          onRedo={() => {
            setScenes(scenes?.redo());
          }}
          onUndo={() => {
            setScenes(scenes?.undo());
          }}
          onDeleteAll={() => setScenes(scenes?.clear())}
        />
      </div>
      <div ref={contentRef} css={style.content}>
        <PinList
          points={points}
          onSeek={Player.setCurrentTime!}
          onChange={(points: Array<Point>) => {
            setScenes(scenes?.push(points));
          }}
        />
      </div>
      <Footer
        current={current}
        onSubmit={async () => {
          if (!current) {
            return;
          }
          Modal.show({
            content: <Processing status="processing" onClose={Modal.hide} />,
          });
          try {
            const res = await processFileAsync(current.file, points);
            if (!res) {
              throw new Error("res is empty");
            }
            const { files } = res;
            const _points = JSON.parse(JSON.stringify(points));
            files.forEach((item: any, index: number) => {
              _points[index].link = item.src;
              _points[index].blob = item.blob;
            });
            const archiveBlob =  await zipFiles(files)

            setScenes(PointsHistory.create(_points));
            const _current = current.setPoints(_points).setArchiveUrl(archiveBlob);
            updateCurrent(_current);

            Modal.hide();
            Modal.show({
              content: <Processing status="success" onClose={Modal.hide} />,
            });
          } catch {
            Modal.hide();
            Modal.show({
              content: <Processing status="error" onClose={Modal.hide} />,
            });
          }
        }}
        onDownload={async () => {
          if (!current) {
            return;
          }
          if (current.archiveUrl) {
            saveAs(current.archiveUrl, current?.archiveName);
            return
          }
        }}
      />
    </div>
  );
};

export default Content;
