import React, { useState } from "react";
import { css } from "@emotion/react";
import { color } from "../../../constants";
import Form from "./Form";
import Default from "./Default";

const style = Object.freeze({
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    background: ${color["gray-1"]};
    border-radius: 8px;
    margin-bottom: 8px;
    transition: background 0.1s linear;
  `,
  editing: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    background: ${color.info};
    box-shadow: 1px 1px 6px 1px #82733f5c;
    border-radius: 8px;
    margin-bottom: 8px;
    transition: background 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  `,
});

interface Point {
  name: string;
  head: number;
  tail: number;
  file?: any;
}

interface Props {
  point: Point;
  onChangePoint?: (point: Point) => void;
  onRemovePoint?: () => void;
  onClickTime?: (currentTime: number) => void;
}

const PointRow: React.FC<Props> = ({
  point,
  onChangePoint,
  onClickTime,
  onRemovePoint,
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <div css={editing ? style.editing : style.container}>
      {editing ? (
        <Form
          point={point}
          onChangePoint={onChangePoint}
          onDone={() => setEditing(false)}
        />
      ) : (
        <Default
          point={point}
          onClickTime={onClickTime}
          onRemovePoint={onRemovePoint}
          onEdit={() => setEditing(true)}
        />
      )}
    </div>
  );
};

export default PointRow;
