import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import TimeValue from "../../models/Time";

const style = Object.freeze({
  min: css`
    grid-area: min;
    width: 2.5rem;
    text-align: right;
  `,
  sec: css`
    grid-area: sec;
    width: 2.5rem;
    text-align: right;
  `,
  delimitor: css`
    grid-area: delimitor;
    width: 1rem;
    text-align: center;
  `,
  container: css`
    display: grid;
    grid-template-areas:
      "min delimitor sec";

    input {
      border: 0;
      border-radius: 4px;
      padding: 2px;
      letter-spacing: 0.1rem;
    }
  `,
});

interface Props {
  time: number;
  onChange?: (time: number) => void;
}

const TimeForm: React.FC<Props> = ({ time, onChange }) => {
  const [value, setValue] = useState<TimeValue>();

  useEffect(() => {
    const v = new TimeValue(time)
    setValue(new TimeValue(time));
  }, []);

  return (
    <div css={style.container}>
      <input
        type="text"
        value={value?.minAsString}
        css={style.min}
        onChange={(e: any) => {
          if (!value) {
            return;
          }
          const input = e.target.value.slice(-2)
          if (!input.match(/^[0-9]*$/)) {
            return;
          }

          const newValue = value.setMin(Number(input || 0));
          setValue(newValue);
          if (onChange) {
            onChange(newValue.data);
          }
        }}
      />
      <span css={style.delimitor}>:</span>
      <input
        type="text"
        value={value?.secAsString}
        css={style.sec}
        onChange={(e: any) => {
          if (!value) {
            return;
          }
          const input = e.target.value.slice(-2)
          if (!input.match(/^[0-9]*$/)) {
            return;
          }
          const newValue = value.setSec(Number(input || 0));
          setValue(newValue);
          if (onChange) {
            onChange(newValue.data);
          }
        }}
      />
    </div>
  );
};

export default TimeForm;
